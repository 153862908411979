@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .kooder-opener-height {
        height: auto;

        --kooder-opener-height: calc(100svh - 6.25rem - 1.5rem);
        @media (min-width: 768px) {
            height: var(--kooder-opener-height);
        }
    }
}
